<template>
  <div :class="classes">
    <div
      :class="[$style.item, tab.active && $style.active]"
      v-for="tab in headers"
      :key="tab.id"
      @click="onSelect(tab.id)"
    >
      <span :class="$style.text">{{ tab.title }}</span>
    </div>
  </div>
</template>

<script lang="ts" generic="T extends string | number" setup>
import { useCssModule, computed } from 'vue'
import { BASE_PROPS_DEFAULTS } from './consts'
import type { BaseProps } from './types'

defineOptions({
  name: 'UiTabsHeader'
})

const $props = withDefaults(
  defineProps<
    BaseProps<T> & {
      // eslint-disable-next-line vue/require-default-prop
      modelValue?: T | null
    }
  >(),
  {
    ...BASE_PROPS_DEFAULTS,

    modelValue: null
  }
)

const $emit = defineEmits<{
  'update:modelValue': [id: T]
}>()

const $style = useCssModule()

const classes = computed(() => [
  $style.header,
  $style[$props.theme],
  $props.uppercase && $style.uppercase
])

const headers = computed(() =>
  $props.tabs.map(item => ({
    id: item.id,
    title: item.title,
    active: item.id === $props.modelValue
  }))
)

const onSelect = (id: T) => $emit('update:modelValue', id)
</script>

<style lang="scss" module>
.header {
  display: flex;
  gap: var(--tabs-gap);

  .item {
    flex: 1 1;
    border-bottom: 2px solid rgba(#41435c, 0.3);
    text-align: center;
    padding: 0.8em 0;
    cursor: pointer;
    &.active {
      border-bottom: 2px solid #fff;
      pointer-events: none;
      .text {
        color: #acb0d5;
      }
    }
    .text {
      font-weight: 500;
      font-size: var(--tabs-header-font-size);
      line-height: 1.5;
      color: #41435c;
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.accent {
  .item {
    border-color: rgba(172, 176, 213, 0.3);

    .text {
      color: #acb0d5;
    }

    &.active {
      border-color: #00c7b1;

      .text {
        color: #00c7b1;
      }
    }
  }
}
</style>
